@import '../sass/variables';

.nav {
    background-color: #fff;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5em;
    gap: 3em;
    &--sections {
        display: flex;
        gap: 2em;
    }
    &--item {
        font-family: 'Barlow Condensed', sans-serif;
        font-size: 1.5em;
        font-weight: 600;
        color: $black;
        text-decoration: none;
        cursor: pointer;
        &:hover {
           color: $primary;
        }
    }
}