@import '../sass/mixins';
@import '../sass/variables';

.modal-- {
    &overlay {
        background-color: rgba(0,0,0, 0.3);
        width: 100vw;
        height: 100vh;
        z-index: 1;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        animation-name: fade-in;
        animation-duration: 4s;
        animation-fill-mode: forwards;
    }
    &card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-color: #fff;
        padding: 10px;
        text-align: center;
        width: auto;
        height: auto;
        padding: 3em;
        border-radius: 8px;
        box-shadow: 2px 4px 10px rgba(0,0,0,0.5);
        animation-name: fade-in;
        animation-duration: 4s;
        animation-fill-mode: forwards;
    }
    &container {
        display: flex;
        justify-content: center;
        gap: 6em;
        text-align: left;
    }
    &title {
        color: $primary;
        margin-bottom: 1em;
        font-size: 1.5em;
        text-shadow: 0px 1px 1px $shadow;
    }
    &subtitle {
        color: $black;
        font-size: 1.2em;
        margin: 0.5em 0;
        text-shadow: 0px 1px 1px $shadow;
    }
    &text {
        font-size: 1em;
        color: #000;
        font-weight: 500;
        span {
            font-size: 1.5em;
            color: $primary;
            text-shadow: 0px 1px 1px $shadow;
        }
    }
    &button {
        @include input;
        width: fit-content;
        padding: 0 1em;
        cursor: pointer;
    }
}

.hidden--true {
    opacity: 0;
}

.hidden--false {
    opacity: 1;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 780px) {   
    .modal-- {
        &overlay {
            animation: none;
        }
        &card {
            animation: none;
        }
    }
}
