@import '../sass/variables';
@import '../sass/mixins';

.mainstay--section {
    background-color: $background-primary;
    padding: $section-padding;
}

.subtitle {
    @include subtitle;
}

@media (max-width: 1024px) {
    .mainstay--section {
        background-image: url(../images/red_c.jpg);
        background-repeat: no-repeat;
        background-position: 100% 10%;
    }
}