@import './sass/variables';
@import './sass/mixins';
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap');

body {
    margin: 0;
    background-color: #f7f8fc;
    font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4 {
    font-family: 'Poppins', sans-serif;
    margin: 0;
}

.title {
    @include subtitle;
    padding-bottom: 0;
}

.background--image {
    width: 100%;
    height: 88vh;
    background-image: url('./images/main_c.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.separate {
    height: 3em;
    width: 100%;
    background-color: $primary;
}

.background--logo {
    position: absolute;
    z-index: 2;
    width: 10em;
    top: 4em;
    right: 12em;
}

@media (max-width: 550px) {
    .background--logo {
        right: 33vw;
    }
}