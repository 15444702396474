@import '../sass/variables';
@import '../sass/mixins';

.location-- {
    &page {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 3em);
        background-color: hsl(207, 100%, 15%);
    }
    &container {
        display: flex;
        flex-wrap: wrap;
        gap: 2.5em;
        height: min-content;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    &card {
        background-color: #fff;
        padding-bottom: 0.2em;
        border-radius: 6px;
        box-shadow: 4px 6px 10px #000;
    }
    &description {
        margin: 0 0.8em;
        border-top: 1px solid #eee;
    }
    &name {
        color: $primary;
        text-shadow: 0px 1px 1px $black;
        font-size: 1.4em;
    }
    &city {
        color: $black;
        font-size: 1em;
        margin: 0;
        border-bottom: 1px solid #eee;
        padding-bottom: 6px;
    }
    &adress {
        font-size: 1em;
        margin: 6px 0;
    }
    &phone {
        font-size: 1em;
        margin: 6px 0;
        padding-bottom: 6px;
        border-bottom: 1px solid #eee;
    }
    &map {
        border: 0;
        border-radius: 6px;
    }
}

@media (max-width: 1159px) {
    .location--container {
        padding: 2em 0;
    }
}