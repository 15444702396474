@import '../sass/variables';


.guitar--container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 3em);
}

.guitar--reference {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.inscription--main {
    background-color: $background-guitar;
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inscription--guitar__l {
    z-index: 1;
    width: 5.92em;
    position: relative;
    animation-name: open_l;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}

.inscription--guitar__r {
    z-index: 1;
    position: relative;
    width: 5.92em;
    animation-name: open_r;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}

.close--true__l {
    animation-name: close_l;
    animation-duration: 3s;
    animation-fill-mode: forwards;  
}

.close--true__r {
    animation-name: close_r;
    animation-duration: 3s;
    animation-fill-mode: forwards;  
}

@keyframes open_l {
    from {
        left: 0;
    }
    
    to {
        left: -20em;
    }
}

@keyframes open_r {
    from {
        left: 0;
    }
    
    to {
        left: 20em;
    }
}

@keyframes close_l {
    from {
        left: -20em;
    }

    to {
        left: 0;
    }
}

@keyframes close_r {
    from {
        left: 20em;
    }

    to {
        left: 0;
    }
}

//ANIMATIONS MOVILE

@media (max-width: 860px) {
    @keyframes open_l {
        from {
            left: 0;
        }
        
        to {
            left: -10em;
        }
    }
    
    @keyframes open_r {
        from {
            left: 0;
        }
        
        to {
            left: 10em;
        }
    }
    
    @keyframes close_l {
        from {
            left: -10em;
        }
    
        to {
            left: 0;
        }
    }
    
    @keyframes close_r {
        from {
            left: 10em;
        }
    
        to {
            left: 0;
        }
    }
}

//NO ANIMATIONS

@media (max-width: 510px) {
    .inscription--guitar__l {
        animation: none;
        right: 0;
        filter: opacity(0.2);
    }
    .inscription--guitar__r {
        animation: none;
        left: 0;
        filter: opacity(0.2);
    }
}