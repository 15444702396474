@import '../sass/variables';
@import '../sass/mixins';

.inscription--container {
    z-index: 2;
    display: flex;
    position: absolute;
    top: 20%
}

.inscription--form {
    display: flex;
    flex-direction: column;
    gap: 2em;
    width: 17.0625em;
}

.inscription--input {
    @include input;
}

.inscription--button {
    @include input;
    cursor: pointer;
    color: $primary;
    font-size: 1.3rem;
    margin-top: 0.5em;
    font-weight: 600;
    text-shadow: 0px 1px 1px $shadow;
    transition: all 0.3s;
    &:disabled {
        cursor: no-drop;
        border-color: $shadow;
        color: $shadow;
    }
}

.form--first {
    animation-name: fade-in;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    right: -7.9em;
}

.form--second {
    right: 9.2em;
}

.form--true {
    opacity: 1;
    display: flex;
}

.form--false {
    display: none;
    opacity: 1;
    position: relative;
    right: 1000em;
}

.finish--true {
    opacity: 0;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 780px) {   
    .form--first {
        animation: none;
    }
}