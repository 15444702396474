@import '../sass/variables';

.footer {
    background-color: $primary;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5em;
    gap: 3em;
    &--sections {
        display: flex;
        gap: 2em;
    }
    &--item {
        font-family: 'Barlow Condensed', sans-serif;
        font-size: 1.5em;
        font-weight: 600;
        color: white;
        text-decoration: none;
        cursor: pointer;
        &:hover {
           color: $black;
        }
    }
}

.copyright {
    display: flex;
    justify-content: center;
    margin: 0 5em;
    &--text {
        text-align: center;
        margin: 4px;
    }
}

@media (max-width: 600px) {
    .copyright {
        margin: 0 2em;
        &--text {
            font-size: 0.8em;
        }
    }
}