@import '../sass/variables';
@import '../sass/mixins';

.about {
    background-color: #fef2e1;
    padding: $section-padding;
}

.about--{
    &subtitle {
        @include subtitle (left, 1.8em);
        padding: 0;
    }
    &container {
        display: flex;
        max-width: 81.25rem;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        gap: 2em;
        height: 25em;
    }
    &button {
        @include input;
        width: 10em;
        transition: all 0.1s;
        min-height: 42px;
        &:hover {
            cursor: pointer;
            color: $primary;
            transform: scale(1.05);
            font-weight: 600;
        }
    }
    &description {
        display: flex;
        flex-direction: column;
        gap: 1em;
        max-width: 31.25em;
        justify-content: space-around;
    }
    &img {
        border-radius: 4px;
        height: 25em;
        box-shadow: 2px 2px 4px $shadow;
    }
}

@media (max-width: 1200px) {
    .about--img {
        height: 15em;
    }
}

@media (max-width: 1024px) {
    .about {
        height: 47em;
        &--container {
            flex-direction: column;
            justify-content: start;
        }
        &--subtitle {
            justify-content: center;
            display: flex;
        }
        &--description {
            align-items: center;
        }
    }
    .description--text {
        text-align: center;
        margin: 0.8em 1em;
    }
}

@media (max-width: 440px) {
    .about--img {
        width: 92%;
        height: auto;
    }
    .about--img__container {
        display: flex;
        justify-content: center;
    }
}
