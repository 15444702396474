@mixin subtitle ($justify: center, $font-size: 2.625em) {
    display: flex;
    justify-content: $justify;
    font-size: $font-size;
    color: $black;
    text-shadow: 0px 1px 1px $shadow;
    padding-bottom: 1em;
    span {
        color: $primary;
    }
    @media (max-width: 600px) {
        display: block;
        text-align: center;
        justify-content: center;
    }
    @media (max-width: 450px) {
        span {
            display: block;
        }
    }
}

@mixin input {
    border: none;
    border-top: 3px solid $primary;
    font-size: 1.2em;
    width: 100%;
    height: 2.2em;
    padding-left: 5px;
    border-radius: 4px;
    outline: none;
    box-shadow: 2px 2px 4px $shadow;
    box-sizing: border-box;
}