@import '../sass/variables';
@import '../sass/mixins';

.teachers {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3em;
    justify-content: center;
    padding: $section-padding;
    margin: 0 1em;
}

.teacher--card {
    display: flex;
    grid-template-columns: 1fr 1fr;
    max-width: 81.25rem;
    margin: 0 2em;
    background-color: white;
    justify-content: flex-start;
    border-radius: 0.5em;
    box-shadow: 3px 4px 6px $shadow;
    &:nth-child(even) {
        flex-direction: row-reverse;
    }

    &__description {
        width: 50%;
    }
    &__img {
        width: 50%;
    }
}

.teacher-- {
    &img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &uziel {
        width: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        filter: drop-shadow(10px -5px 10px $shadow);
    }
    &fernando {
        width: 76%;
        position: absolute;
        bottom: 0;
        right: 4em;
        filter: drop-shadow(10px -5px 10px $shadow);
    }
}

.description-- {
    &container {
        display: flex;
        flex-direction: column;
        height: 90%;
        justify-content: space-between;
        margin: 1em 2em;
    }
    &title {
        font-size: 2.1em;
        color: $primary;
        font-weight: 800;
        text-shadow: 0px 1px 1px $shadow;
    }  
    &subtitle {
        color: #333;
        font-size: 1.75em;
        font-weight: 600;
    }
    &text {
        color: #222;
        font-weight: 400;
        font-size: 1.125em;
        margin: 0.8em 1em;
    }
    &icons {
        display: flex;
        gap: 2em;
        :hover {
            transition: all ease 0.25s;
            transform: scale(1.05);
        }
    }
    &icon {
        width: 2.5em;
        cursor: pointer;
        filter: drop-shadow(0px 1px 1px $shadow);
    }
}

.subtitle {
    @include subtitle;
}

//MEDIA QUERIES

@media (max-width: 1024px) {
    .techer--img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .teacher--card {
        flex-direction: column;
        margin: 0;
        &:nth-child(even) {
            flex-direction: column;
        }
        &__description {
            display: flex;
            justify-content: center;
            width: 100%;
        }
        &__img {
            width: 100%;
        }
    }
    .description--container {
        width: 90%;
    }
    .description--text {
        text-align: left;
    }
} 

@media (max-width: 560px) {
    .description--title {
        font-size: 1.8em;
        text-align: center;
    }
    .description--subtitle {
        font-size: 1.5em;
        text-align: left;
    }
    .teacher--selfie {
        width: 90%;
    }
}