@import '../sass/variables';

.card--container {
    display: flex;
    justify-items: flex-start;
    max-width: 81.25rem;
    gap: 8em;
    margin: 0 auto;
    background-color: $background-primary;
    &__left {
        display: flex;
        justify-content: left;
    }
    &__right {
        display: flex;
        gap: 3em;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
}

.card {
    background-color: $primary;
    padding: 0 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    min-height: 7.6em;
    border-radius: 0.5em;
    box-shadow: 3px 3px 8px $shadow;
    &--img {
        max-width: 30em;
        transform: scaleX(-1);
    }
    &--text {
        font-size: 2em;
        text-align: center;
        color: #fff;
        font-weight: 400;
        padding: 1rem 0;
        margin: 0;
        text-shadow: 2px 2px 4px black;
    }
}

@media (max-width: 1200px) {
    .card--container {
        gap: 5em;
    }
}

@media (max-width: 1024px) {
    .card--container {
        background-color: transparent;
        display: flex;
        flex-direction: row;
        width: 83%;
        margin: 0 auto;
        &__left {
            display: none;
        }
        &__right {
            justify-content: center;
            align-items: center;
        }
    }
}
